export const menu1 = [{
  title: 'Home',
  id: 'landing-home',
  to: {
    path: '/',
    hash: 'landing-home'
  }
}, {
  title: 'Features',
  id: 'landing-features',
  to: {
    path: '/',
    hash: 'landing-features'
  }
}, {
  title: 'Fees',
  id: 'landing-fees',
  to: {
    path: '/',
    hash: 'landing-fees'
  }
}, {
  title: 'Contact',
  to: '/contact'
}]

export const menu2 = [{
  title: 'Product'
}, {
  title: 'Features'
}, {
  title: 'Marketplace'
}, {
  title: 'Company'
}]

export default {
  menu1,
  menu2
}
