<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          :to="item.to"
          link
          @click="scrollTo(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <template v-slot:append>
        <div class="pa-2 mb-10">
          <router-link to="/login">
            <v-btn block class="mb-2">
              Log In
            </v-btn>
          </router-link>
          <router-link to="/register">
            <v-btn block class="primary">
              Register
            </v-btn>
          </router-link>
        </div>
      </template> -->
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app height="80">
      <v-container class="py-0 px-0 px-sm-2 fill-height" :fluid="isFluid">
        <router-link to="" class="d-flex align-center text-decoration-none mr-2">
          <!-- <img src="/images/v-logo-small.png" alt="" height="48" class="mr-1"> -->
          <span class="font-weight-black text-uppercase">
            Oxy Fitness
          </span>
        </router-link>

        <div class="d-none d-md-block">
          <v-btn
            v-for="(item, index) in menu"
            :key="index"
            text
            class="mx-1"
            :to="item.to"
            @click="scrollTo(item.id)"
          >
            {{ item.title }}
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <!-- <router-link to="/login">
          <v-btn large text>
            Log In
          </v-btn>
        </router-link>
        <router-link to="/register">
          <v-btn class="mx-1 d-none d-sm-inline-block" color="primary" large>
            Register
          </v-btn>
        </router-link> -->
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { menu1 as menu } from './menus.js'

export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: null,
      menu
    }
  },
  methods: {
    scrollTo(id) {
      if (!id)
        return

      const el = document.getElementById(id)

      el.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
</style>
